import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Form from "./components/Form";
import ChatWithUs from "../../components/chatWithUs";
import { useTranslation } from "react-i18next";
export default function ContactFrom() {
  const [t] = useTranslation("global");
  return (
    <>
      <Header />
      <Banner
        bannerProps={{
          title: t("Form.bannerTitle"),
          subtitle: t("Form.bannerDesc"),
          imgURL: "/contactBanner.png",
        }}
      />
      <Form />
      <ChatWithUs />
      <Footer />
    </>
  );
}
