import { Button } from "@mui/material";

export default function IconButton({ children, sx, checked }) {
  return (
    <Button
      disabled={!checked}
      type="submit"
      sx={{
        ...sx,
        width: "fit-content",
      }}
    >
      {children}
    </Button>
  );
}
