import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Menu, Row, Col, Popover, Button } from "antd";
import Logo from "./images/Logo.svg";
import Tracking from "./Tracking";
import { Trans, withTranslation } from "react-i18next";
import Language from "./Language";
import Chatbot from "./Chatbot";
import { Alert } from "antd";
import i18next from "i18next";
import "./styles/header.css";

const { SubMenu } = Menu;

const Header = ({ t, i18n, isFirstScreen, isMoblie }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const onMenuVisibleChange = (visible) => {
    setMenuVisible(visible);
  };

  const handleShowMenu = () => {
    setMenuVisible(true);
  };

  const handleHideMenu = () => {
    setMenuVisible(false);
  };

  const menuMode = isMoblie ? "inline" : "horizontal";
  const headerClassName = classNames({
    clearfix: true,
    "home-nav-white": !isFirstScreen,
  });

  // let language = "es";

  // if (i18next?.languages?.length) {
  //   language = i18next.languages[0];
  // }

  const menu = (
    <Menu mode={menuMode} id="nav" key="nav">
      <Menu.Item key="docs/services">
        <a href="#services">
          <Trans i18nKey="global:Header.services"></Trans>
        </a>
      </Menu.Item>
      <Menu.Item key="docs/hubs">
        <a href="#hubs">
          <Trans i18nKey="global:Header.hubs"></Trans>
        </a>
      </Menu.Item>
      <SubMenu
        key="Contact"
        title={<Trans i18nKey="global:Header.support"></Trans>}
      >
        <Menu.ItemGroup>
          <Menu.Item key="customer-service">
            <a rel="noopener noreferrer" href="/atencionCliente">
              <Trans i18nKey="global:Header.support"></Trans>
            </a>
          </Menu.Item>
          <SubMenu
            key="download-ticket"
            title={<Trans i18nKey="global:Header.ticket"></Trans>}
            popupClassName="two-column-submenu"
          >
            <Menu mode="vertical" selectedKeys={[]}>
              <Menu.ItemGroup>
                <Menu.Item key="region-andalucia">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/andalucia.pdf"
                    download
                  >
                    Andalucía
                  </a>
                </Menu.Item>
                <Menu.Item key="region-aragon">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/aragon.pdf"
                    download
                  >
                    Aragón
                  </a>
                </Menu.Item>
                <Menu.Item key="region-asturias">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/asturias.pdf"
                    download
                  >
                    Asturias
                  </a>
                </Menu.Item>
                <Menu.Item key="region-balearic-islands">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/baleares.pdf"
                    download
                  >
                    Islas Baleares
                  </a>
                </Menu.Item>
                <Menu.Item key="region-canarias">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/canarias.pdf"
                    download
                  >
                    Canarias
                  </a>
                </Menu.Item>
                <Menu.Item key="region-cantabria">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/cantabria.pdf"
                    download
                  >
                    Cantabria
                  </a>
                </Menu.Item>
                <Menu.Item key="region-castilla-la-mancha">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/castillaLaMancha.pdf"
                    download
                  >
                    Castilla-La Mancha
                  </a>
                </Menu.Item>
                <Menu.Item key="region-castilla-y-leon">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/castillaLeon.pdf"
                    download
                  >
                    Castilla y León
                  </a>
                </Menu.Item>
                <Menu.Item key="region-catalunya">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/catalunya.pdf"
                    download
                  >
                    Cataluña
                  </a>
                </Menu.Item>
                <Menu.Item key="region-extremadura">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/extremadura.pdf"
                    download
                  >
                    Extremadura
                  </a>
                </Menu.Item>
                <Menu.Item key="region-galicia">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/galicia.pdf"
                    download
                  >
                    Galicia
                  </a>
                </Menu.Item>
                <Menu.Item key="region-la-rioja">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/laRioja.pdf"
                    download
                  >
                    La Rioja
                  </a>
                </Menu.Item>
                <Menu.Item key="region-madrid">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/madrid.pdf"
                    download
                  >
                    Comunidad de Madrid
                  </a>
                </Menu.Item>
                <Menu.Item key="region-murcia">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/murcia.pdf"
                    download
                  >
                    Región de Murcia
                  </a>
                </Menu.Item>
                <Menu.Item key="region-navarra">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/navarra.pdf"
                    download
                  >
                    Navarra
                  </a>
                </Menu.Item>
                <Menu.Item key="region-basque-country">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/paisVasco.pdf"
                    download
                  >
                    País Vasco
                  </a>
                </Menu.Item>
                <Menu.Item key="region-valencian-community">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/valencia.pdf"
                    download
                  >
                    Comunidad Valenciana
                  </a>
                </Menu.Item>
                <Menu.Item key="region-ceuta">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/ceuta.pdf"
                    download
                  >
                    Ceuta
                  </a>
                </Menu.Item>
                <Menu.Item key="region-melilla">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/hojas/melilla.pdf"
                    download
                  >
                    Melilla
                  </a>
                </Menu.Item>
                {/* Add more regions as needed */}
              </Menu.ItemGroup>
            </Menu>
          </SubMenu>
        </Menu.ItemGroup>
      </SubMenu>
      <SubMenu
        key="lang"
        title={<Trans i18nKey="global:Header.languaje"></Trans>}
      >
        <Menu.ItemGroup>
          <Menu.Item key="lang">
            <Language />
          </Menu.Item>
        </Menu.ItemGroup>
      </SubMenu>
      <Menu.Item key="docs/sigin">
        <Button type="primary" shape="round">
          <a
            style={{ color: "white" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.ecoscooting.com/login"
          >
            <Trans i18nKey="global:Header.login"></Trans>
          </a>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <header id="header" className={headerClassName}>
      {menuMode === "inline" ? (
        <Popover
          overlayClassName="popover-menu"
          placement="bottomRight"
          content={menu}
          trigger="click"
          visible={menuVisible}
          arrowPointAtCenter
          onVisibleChange={onMenuVisibleChange}
        ></Popover>
      ) : null}
      <Row>
        <Col lg={1} md={2} sm={5} xs={5}>
          <a id="logo">
            <img alt="logo" src={Logo} />
          </a>
        </Col>
        <Col xl={5} lg={7} md={8} sm={15} xs={15}>
          <Tracking />
        </Col>
        <Col xl={18} lg={13} md={12} sm={4} xs={2}>
          {menuMode === "horizontal" ? menu : null}
        </Col>
      </Row>
    </header>
  );
};

Header.propTypes = {
  isFirstScreen: PropTypes.bool,
  isMoblie: PropTypes.bool,
};

export default withTranslation("global")(Header);
