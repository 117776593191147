import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { useState } from "react";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "../../app/hooks/useBreakpoints";
import { ArrowDropDown } from "@mui/icons-material";
import { THEME_COLOR } from "../../app/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { changeLan, selectLan } from "../../Home/headerSlice";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const drawerWidth = 240;
const navItems = [
  { title: "ToDoor.toDoor", link: "/toDoor/" },
  { title: "Header.lockerPudo", link: "/pickUpPoint/" },
  { title: "Header.support", link: "/atencionCliente/" },
  // { title: "Header.companies", link: "#top" },
  // {
  //   title: "Header.languaje",
  //   subItems: ["English", "Español", "Portuguese"],
  // },
  // { title: "Header.login", link: "https://play.ecoscooting.com/login" },
];

export default function Header(props) {
  const location = useLocation();
  console.log(location.pathname);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [empAnchorEl, setEmpAnchorEl] = useState(null);
  const currentLan = useSelector(selectLan);
  const [t, i18n] = useTranslation("global");
  const { xs } = useBreakpoints();
  const [lan, setLan] = useState(currentLan);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleEmpClick = (event) => {
    event.stopPropagation();
    setEmpAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmpClose = () => {
    setEmpAnchorEl(null);
  };

  const handleChangeLan = (item) => {
    switch (item) {
      case "English":
        dispatch(changeLan("en"));
        i18n.changeLanguage("en");
        setLan("en");
        break;
      case "Español":
        dispatch(changeLan("es"));
        i18n.changeLanguage("es");
        setLan("es");
        break;
      case "Portuguese":
        dispatch(changeLan("pt"));
        i18n.changeLanguage("pt");
        setLan("pt");
        break;
      default:
        break;
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MENU
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            {item.title === "Header.languaje" ? (
              <ListItemButton
                onClick={(e) => {
                  handleClick(e);
                }}
                endIcon={<ArrowDropDown />}
                sx={{ textTransform: "none", display: "flex" }}
              >
                <Box
                  component="img"
                  src={
                    lan === "en"
                      ? "https://img.icons8.com/office/30/000000/usa.png"
                      : lan === "es"
                      ? "https://img.icons8.com/office/30/000000/spain-2.png"
                      : lan === "pt"
                      ? "https://img.icons8.com/office/30/000000/portugal.png"
                      : ""
                  }
                  alt="current language"
                  sx={{ width: 20, height: 20 }}
                />
                <ArrowDropDown style={{ marginLeft: 8 }} />
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{ textAlign: "center", cursor: "pointer" }}
                component="a"
                href={item.link}
              >
                <ListItemText primary={t(item.title)} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const open = Boolean(anchorEl);
  const empOpen = Boolean(empAnchorEl);

  return (
    <Box display="flex" justifyContent="space-between">
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "#1B5FC0",
          // backgroundImage: 'url("/background.png")',
          height: "140px",
          boxShadow: "none",
        }}
      >
        <Toolbar
          display="flex"
          justifyContent="space-between"
          sx={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
          }}
        >
          <Box width="100%">
            <Box gap={2} display="flex" alignItems="center" width="100%">
              <Box display="flex" gap={1}>
                <AccessTimeIcon />
                <Typography
                  variant="subtitle1"
                  sx={{ opacity: "40%", color: "white" }}
                >
                  Lun a Vier 09:00h a 18:00h - Sáb y Dom 09:00h a 13:00h
                </Typography>
              </Box>
              <Box display="flex" gap={1} alignItems="center">
                <SupportAgentIcon />
                <Box
                  component="a"
                  href={t("ChatBot.urlChat")}
                  sx={{ "&:hover": { opacity: "0.5" } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ opacity: "40%", color: "white" }}
                  >
                    {"Chat"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Button
            component="div"
            onClick={(e) => {
              handleClick(e);
            }}
            disableRipple
          >
            <Box
              component="img"
              src={
                lan === "en"
                  ? "https://img.icons8.com/office/30/000000/usa.png"
                  : lan === "es"
                  ? "https://img.icons8.com/office/30/000000/spain-2.png"
                  : lan === "pt"
                  ? "https://img.icons8.com/office/30/000000/portugal.png"
                  : ""
              }
              sx={{
                "&:hover": {
                  color: THEME_COLOR,
                },
              }}
            />
            <ArrowDropDown />
          </Button>
        </Toolbar>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mx: 1, display: { xs: "block", md: "none" }, color: "white" }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="a"
            href="/"
            sx={{
              flexGrow: 1,
              display: "block",

              pt: 1,
            }}
          >
            <Box
              component="img"
              src="/logo.png"
              alt="logo"
              width="200px"
              height="50px"
            />
          </Box>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, mt: "10px" }}
            gap={10}
          >
            {navItems.map((item) =>
              item.title === "Header.services" ? (
                <Button
                  component="div"
                  onClick={(e) => {
                    handleEmpClick(e);
                  }}
                  disableRipple
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{
                      color: "white",
                      "&:hover": {
                        color: THEME_COLOR,
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {t(item.title)}
                  </Typography>
                  <ArrowDropDown />
                </Button>
              ) : (
                <Button
                  component="a"
                  href={item.link}
                  key={item.title}
                  disableRipple
                  sx={{
                    backgroundColor:
                      location.pathname === item.link ? "#5EA9F4" : "",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{
                      color: "white",
                      "&:hover": {
                        color: "THEME_COLOR",
                        fontWeight: "bold",
                        opacity: 0.5,
                      },
                    }}
                  >
                    {t(item.title)}
                  </Typography>
                </Button>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* Menu Items */}
        <MenuItem
          onClick={() => {
            handleChangeLan("Español");
            handleClose();
            xs && closeDrawer();
          }}
        >
          <ListItemIcon>
            <img
              src="https://img.icons8.com/office/30/000000/spain-2.png"
              alt="Español"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          Español
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChangeLan("Portuguese");
            handleClose();
            xs && closeDrawer();
          }}
        >
          <ListItemIcon>
            <img
              src="https://img.icons8.com/office/30/000000/portugal.png"
              alt="Português"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          Português
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChangeLan("English");
            handleClose();
            xs && closeDrawer();
          }}
        >
          <ListItemIcon>
            <img
              src="https://img.icons8.com/office/30/000000/usa.png"
              alt="English"
              style={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          English
        </MenuItem>
      </Menu>
      {/* sep */}
      <Menu
        anchorEl={empAnchorEl}
        open={empOpen}
        onClose={handleEmpClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* Menu Items */}
        <MenuItem>
          <Button href="/toDoor">
            <Typography variant="body2">{t("Header.toDoor")}</Typography>
          </Button>
        </MenuItem>
        <MenuItem>
          <Button href="/pickUpPoint">
            <Typography variant="body2">{t("Header.lockerPudo")}</Typography>
          </Button>
        </MenuItem>
        {/* <MenuItem>
          <Button href="/technology">
            <Typography variant="body2">Technology</Typography>
          </Button>
        </MenuItem> */}
      </Menu>
    </Box>
  );
}
