import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import IconButton from "../../../components/IconButton";
import { useLocation } from "react-router-dom";
import { useBreakpoints } from "../../../app/hooks/useBreakpoints";
import { useTranslation } from "react-i18next";

export default function Form() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isCustomer = queryParams.get("isCustomer") === "true";
  const { lg } = useBreakpoints();
  const [t] = useTranslation("global");

  const initialValue = isCustomer
    ? {
        companyName: "",
        id: "",
        phoneNumber: "",
        contactName: "",
        email: "",
        address: "",
        zipCode: "",
        location: "",
        shipmentPlace: "",
        volumn: "",
        technology: "",
        question: "",
      }
    : {
        companyName: "",
        id: "",
        phoneNumber: "",
        contactName: "",
        email: "",
        address: "",
        zipCode: "",
        location: "",
        space: "",
        socket: "",
        question: "",
      };
  const [formValue, setFormValue] = useState(initialValue);
  const [checked, setChecked] = useState(false);

  const handleSubmit = () => {
    setFormValue(initialValue);
  };

  return (
    <Stack height="100%" alignItems="center" bgcolor="#F7F7F7" gap={2}>
      <Box width={lg ? "900px" : "300px"}>
        <Typography my={6}>
          {isCustomer ? t("Form.question") : t("Form.questionLocker")}
        </Typography>
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        style={{
          height: "100%",
        }}
      >
        <Stack
          gap={2}
          p={5}
          borderRadius={3}
          sx={{
            backgroundColor: "white",
          }}
          mb={6}
          alignItems="center"
        >
          <Box
            display="flex"
            gap={2}
            flexDirection={lg ? "row" : "column"}
            justifyContent="center"
            alignItems="center"
          >
            <StyledTextField
              value={formValue.companyName}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  companyName: e.target.value,
                });
              }}
              label={t("Form.companyName")}
              isPC={lg}
              required
            />
            <StyledTextField
              value={formValue.id}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  id: e.target.value,
                });
              }}
              label={t("Form.nif")}
              isPC={lg}
              required
            />
          </Box>
          <Box display="flex" gap={2} flexDirection={lg ? "row" : "column"}>
            <StyledTextField
              value={formValue.phoneNumber}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  phoneNumber: e.target.value,
                });
              }}
              label={t("Form.phone")}
              isPC={lg}
              required
            />
            <StyledTextField
              value={formValue.contactName}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  contactName: e.target.value,
                });
              }}
              label={t("Form.contactName")}
              isPC={lg}
              required
            />
          </Box>
          <Box display="flex" gap={2} flexDirection={lg ? "row" : "column"}>
            <StyledTextField
              value={formValue.email}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  email: e.target.value,
                });
              }}
              label={t("Form.email")}
              isPC={lg}
              required
            />
            <StyledTextField
              value={formValue.address}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  address: e.target.value,
                });
              }}
              label={t("Form.address")}
              isPC={lg}
              required
            />
          </Box>
          <Box display="flex" gap={2} flexDirection={lg ? "row" : "column"}>
            <StyledTextField
              value={formValue.zipCode}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  zipCode: e.target.value,
                });
              }}
              label={t("Form.zipCode")}
              isPC={lg}
              required
            />
            <StyledTextField
              value={formValue.location}
              onInputChange={(e) => {
                setFormValue({
                  ...formValue,
                  location: e.target.value,
                });
              }}
              label={t("Form.location")}
              isPC={lg}
              required
            />
          </Box>
          <Box display="flex" gap={2} flexDirection={lg ? "row" : "column"}>
            {isCustomer ? (
              <StyledTextField
                value={formValue.shipmentPlace}
                onInputChange={(e) => {
                  setFormValue({
                    ...formValue,
                    shipmentPlace: e.target.value,
                  });
                }}
                label={t("Form.shipmentPlace")}
                isPC={lg}
                isSelect
                required
              >
                <MenuItem value="option1">{t("Form.national")}</MenuItem>
                <MenuItem value="option2">{t("Form.international")}</MenuItem>
              </StyledTextField>
            ) : (
              <StyledTextField
                value={formValue.space}
                onInputChange={(e) => {
                  setFormValue({
                    ...formValue,
                    space: e.target.value,
                  });
                }}
                label={t("Form.space")}
                isPC={lg}
                isSelect
                required
              >
                <MenuItem value="option1">
                  {"< 2m"}
                  <sup>2</sup>
                </MenuItem>
                <MenuItem value="option2">
                  2m<sup>2</sup>-4m<sup>2</sup>
                </MenuItem>
                <MenuItem value="option2">
                  4m<sup>2</sup>-7m<sup>2</sup>
                </MenuItem>
                <MenuItem value="option2">
                  {"> 7m"}
                  <sup>2</sup>
                </MenuItem>
              </StyledTextField>
            )}
            {isCustomer ? (
              <StyledTextField
                value={formValue.volumn}
                onInputChange={(e) => {
                  setFormValue({
                    ...formValue,
                    volumn: e.target.value,
                  });
                }}
                label={t("Form.volumn")}
                isPC={lg}
                isSelect
                required
              >
                <MenuItem value="option1">{t("Form.shipment1")}</MenuItem>
                <MenuItem value="option2">{t("Form.shipment2")}</MenuItem>
                <MenuItem value="option3">{t("Form.shipment3")}</MenuItem>
              </StyledTextField>
            ) : (
              <StyledTextField
                value={formValue.socket}
                onInputChange={(e) => {
                  setFormValue({
                    ...formValue,
                    socket: e.target.value,
                  });
                }}
                label={t("Form.socket")}
                isPC={lg}
                isSelect
                required
              >
                <MenuItem value="option1">{t("Form.yes")}</MenuItem>
                <MenuItem value="option2">No</MenuItem>
              </StyledTextField>
            )}
          </Box>

          {isCustomer ? (
            <Box display="flex" gap={2} flexDirection={lg ? "row" : "column"}>
              <StyledTextField
                value={formValue.technology}
                onInputChange={(e) => {
                  setFormValue({
                    ...formValue,
                    technology: e.target.value,
                  });
                }}
                label={t("Form.technology")}
                isPC={lg}
                isSelect
                required
              >
                <MenuItem value="option1">Sorter</MenuItem>
                <MenuItem value="option2">PDA</MenuItem>
                <MenuItem value="option3">RFID</MenuItem>
                <MenuItem value="option3">Lockers</MenuItem>
                <MenuItem value="option3">Automatización</MenuItem>
              </StyledTextField>

              <Box
                display="flex"
                gap={2}
                flexDirection={lg ? "row" : "column"}
                alignSelf={lg && "flex-start"}
              >
                <TextField
                  label={t("Form.comments")}
                  value={formValue.questions}
                  onChange={(e) => {
                    setFormValue({
                      ...formValue,
                      questions: e.target.value,
                    });
                  }}
                  sx={{
                    width: lg ? "500px" : "200px",
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              gap={2}
              flexDirection={lg ? "row" : "column"}
              alignSelf={lg && "flex-start"}
            >
              <TextField
                label={t("Form.comments")}
                value={formValue.questions}
                onChange={(e) => {
                  setFormValue({
                    ...formValue,
                    questions: e.target.value,
                  });
                }}
                sx={{
                  width: lg ? "500px" : "200px",
                }}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Box>
          )}

          <Box
            display="flex"
            gap={1}
            alignItems="center"
            alignSelf="flex-start"
          >
            <Checkbox
              name="consent"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
              inputProps={{
                "aria-label": "Controlled Checkbox",
              }}
            />
            <label for="consent">
              <Typography>
                {t("Form.consent")}
                <a href="/politica-privacidad">{t("Form.privatePolicy")}</a>
              </Typography>
            </label>
          </Box>
          <IconButton
            checked={checked}
            sx={{
              opacity: !checked ? 0.5 : 1,
              "&:hover": {
                cursor: checked && "pointer",
              },
              margin: "0 auto",
              position: "relative",
            }}
          >
            <Box component="img" src="/blueButton.png" width="150px" />
            <Typography
              sx={{
                position: "absolute",
                top: 20,
              }}
              fontWeight={500}
              color="white"
            >
              {t("Form.send")}
            </Typography>
          </IconButton>
        </Stack>
      </form>
    </Stack>
  );
}

function StyledTextField({
  value,
  onInputChange,
  label,
  isPC,
  isSelect,
  children,
  required,
}) {
  return (
    <TextField
      required={required}
      label={label}
      value={value}
      onChange={onInputChange}
      sx={{
        width: isPC ? "500px" : "200px",
        alignSelf: "center",
      }}
      select={isSelect}
      variant={isSelect && "outlined"}
    >
      {children}
    </TextField>
  );
}
