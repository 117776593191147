import "./style.js";
import "antd/dist/antd.css";
import { Route, Switch } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Privacidad from "./LPD/Pol-Priv.jsx";
import PolCookies from "./LPD/Cookies.jsx";
import Aviso from "./LPD/Aviso-legal.jsx";
import CookiesConsent from "./LPD/CookiesConsent.jsx";
import Home from "./Home/Home.jsx";
import CookieBot from "react-cookiebot/lib/CookieBot";
import Portal from "./Portal/Portal.jsx";
import Img from "./Home/Img-prev.jsx";
import CustomerServicePage from "./features/Customer/index.jsx";
import { ThemeProvider } from "@mui/material";
import theme from "./app/config/styles/theme.js";
import { useSelector } from "react-redux";

import Tracking from "./features/Tracking/index.jsx";

import i18next from "i18next";
import global_es from "./app/config/translations/es/global.json";
import global_en from "./app/config/translations/en/global.json";
import global_pt from "./app/config/translations/pt/global.json";
import { I18nextProvider } from "react-i18next";
import { selectLan } from "./Home/headerSlice.js";
import ToDoor from "./features/ToDoor/index.jsx";
import HomePage from "./features/HomePage/index.jsx";
import Technology from "./features/Technology/index.jsx";
import PickUpPoint from "./features/PickUpPoint/index.jsx";
import ContactForm from "./features/ContactForm/index.jsx";

const domainGroupId = "81b48914-7d4a-454b-97fa-d954c5c73897";

function App() {
  const lan = useSelector(selectLan);
  i18next.init({
    interpolation: { escapeValue: false },
    lng: lan,
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
      pt: {
        global: global_pt,
      },
    },
  });

  // useEffect(() => {
  //   const navigatorLang = navigator.language;

  //   let language = "en";

  //   if (navigatorLang.includes("es")) {
  //     language = "es";
  //   } else if (navigatorLang.includes("pt")) {
  //     language = "pt";
  //   }

  //   i18next.changeLanguage(language);
  // }, []);

  return (
    <>
      <I18nextProvider i18n={i18next}>
        <CookieBot domainGroupId={domainGroupId} language="ES" />
        <Switch>
          <ThemeProvider theme={theme}>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/homeDev" component={HomePage} />
            <Route exact path="/politica-privacidad" component={Privacidad} />
            <Route exact path="/aviso-legal" component={Aviso} />
            <Route exact path="/politica-cookies" component={PolCookies} />
            {/* <Route exact path="/cookies-consent" component={CookiesConsent} /> */}
            <Route exact path="/shipmenttracking" component={Portal} />
            {/* <Route exact path="/img-prev" component={Img} /> */}
            <Route path="/tracking/:id" component={Tracking} />
            <Route
              exact
              path="/atencionCliente"
              component={CustomerServicePage}
            />
            <Route exact path="/toDoor" component={ToDoor} />
            <Route exact path="/pickUpPoint" component={PickUpPoint} />
            <Route path="/contactForm" component={ContactForm} />
            <Route exact path="/technology" component={Technology} />
          </ThemeProvider>
        </Switch>
      </I18nextProvider>
    </>
  );
}

export default App;
