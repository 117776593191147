// import Banner from "../../components/Banner";
// import ContactSection from "../../components/ContactSection";
// import Footer from "../../components/Footer";
// import Header from "../../components/Header";
// import Cards from "./components/Cards";

// export default function Technology() {
//   const bannerProps = {
//     title: 'Tecnologia',
//     subtitle: 'Tenemos la solución logística para tu empresa',
//     imgURL: '/todo.png'
//   }

//   return (
//     <>
//       <Header />
//       <Banner bannerProps={bannerProps} />
//       <Cards />
//       <ContactSection />
//       <Footer />
//     </>
//   );
// }
import React, { useState } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";

function HoverMenuExample() {
  const [anchorEl, setAnchorEl] = useState(null);

  // 打开菜单
  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // 关闭菜单
  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      onMouseLeave={handleMouseLeave} // 监听鼠标移出整个区域
      style={{ display: "inline-block" }}
    >
      <Typography
        onMouseEnter={handleMouseEnter} // 监听鼠标移入按钮
        style={{ cursor: "pointer", color: "blue" }}
      >
        悬浮打开菜单
      </Typography>
      <Menu
        id="hover-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMouseLeave}
        MenuListProps={{
          onMouseEnter: handleMouseEnter, // 防止鼠标进入菜单时关闭
          onMouseLeave: handleMouseLeave, // 鼠标离开菜单时关闭
        }}
      >
        <MenuItem onClick={handleMouseLeave}>选项 1</MenuItem>
        <MenuItem onClick={handleMouseLeave}>选项 2</MenuItem>
        <MenuItem onClick={handleMouseLeave}>选项 3</MenuItem>
      </Menu>
    </div>
  );
}

export default HoverMenuExample;
